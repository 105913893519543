// src/App.js
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import './App.scss';

const App = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <Home />
        <About />
      </main>
      <Footer />
    </div>
  );
};

export default App;
