// src/components/Home.js
import React from 'react';
// import './Home.scss';

const Home = () => {
  return (
    <section id="home" className="home">
      <h2>Welcome to in3gue</h2>
      <p>Your partner in innovative software solutions.</p>
    </section>
  );
};

export default Home;
